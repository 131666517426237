import React from 'react';
import Launchpad from './Pages/Launchpad/index'; // Kutucuklar komponentini içe aktarın

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Launchpad />
      </header>
    </div>
  );
}

export default App;