import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { QrcodeOutlined, CalendarTwoTone, PushpinTwoTone, FolderOpenTwoTone, FundTwoTone, DiffTwoTone, SnippetsTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import { GoogleOutlined, PoweroffOutlined, CarTwoTone, IdcardTwoTone } from '@ant-design/icons';
import secureLocalStorage from "react-secure-storage";
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY;


const Launchpad = () => {
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCeo, setIsCeo] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isEditor, setIsEditor] = useState(false);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod/.test(userAgent.toLowerCase());
  };
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  const decryptData = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  const secureSetItem = (key, value) => {
    if (isMobileDevice()) {
      const encryptedValue = encryptData(value);
      localStorage.setItem(key, encryptedValue);
    } else {
      try {
        secureLocalStorage.setItem(key, value);
      } catch (error) {
        console.error("secureLocalStorage setItem hatası:", error);
        const encryptedValue = encryptData(value);
        localStorage.setItem(key, encryptedValue);
      }
    }
  };

  const secureGetItem = (key) => {
    if (isMobileDevice()) {
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) {
        return null;
      }
      try {
        return decryptData(encryptedValue);
      } catch (error) {
        console.error("Veri çözümlenemedi:", error);
        return null;
      }
    } else {
      try {
        return secureLocalStorage.getItem(key) || localStorage.getItem(key);
      } catch (error) {
        console.error("secureLocalStorage getItem hatası:", error);
        const encryptedValue = localStorage.getItem(key);
        if (!encryptedValue) {
          return null;
        }
        try {
          return decryptData(encryptedValue);
        } catch (error) {
          console.error("Veri çözümlenemedi:", error);
          return null;
        }
      }
    }
  };

  const secureRemoveItem = (key) => {
    if (isMobileDevice()) {
      localStorage.removeItem(key);
    } else {
      try {
        secureLocalStorage.removeItem(key);
      } catch (error) {
        console.error("secureLocalStorage removeItem hatası:", error);
        localStorage.removeItem(key);
      }
    }
  };

  const handleLaunchpadClick = (path) => {
    window.location.pathname = path;
  };

  const fetchEditor = async () => {
    const profileData = JSON.parse(secureGetItem('profile'));
    if (profileData) {
      const userEmail = profileData.email;
      const editor = [
        "mustafa.gevze@fit-global.com",
        "mucahit.kaya@fit-global.com"
      ];
      if (editor.includes(userEmail)) {
        setIsEditor(true);
      }
    }
  };

  const fetchUserRole = async (email) => {
    try {
      const profileData = JSON.parse(secureGetItem('profile'));
      let sEmail = email ? email : profileData.email;
      const userRef = await fetch(`https://fitalim.fit-global.com/api/FilterGoogleUsers/${sEmail}`);
      const docSnap = await userRef.json();

      if (docSnap[0]) {
        const role = docSnap[0].role;
        setUserRole(role);

        setIsAdmin(role === 'admin');
        setIsCeo(role === 'ceo');
        setIsUser(role === 'user');
      }
    } catch (error) {
      console.error("Kullanıcı rolü alınamadı:", error);
      logOut();
    }
  };

  useEffect(() => {
    fetchEditor();
    fetchUserRole();
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
      secureSetItem('user', JSON.stringify({
        access_token: codeResponse.access_token
      }));

      try {
        const userInfoResponse = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
          }
        });

        const userInfo = userInfoResponse.data;
        setProfile(userInfo);
        secureSetItem('profile', JSON.stringify({
          id: userInfo.id,
          email: userInfo.email,
          verified_email: userInfo.verified_email,
          name: userInfo.name,
          given_name: userInfo.given_name,
          family_name: userInfo.family_name,
          picture: userInfo.picture,
          locale: userInfo.locale
        }));
        fetchUserRole(userInfo.email);
      } catch (error) {
        console.error("Kullanıcı bilgileri alınamadı:", error);
      }
    },
    onError: (error) => {
      console.log('Login Failed:', error);
    }
  });

  const logOut = async () => {
    googleLogout();
    setUser(null);
    setProfile(null);
    setUserRole(null);
    setIsAdmin(false);
    setIsCeo(false);
    setIsUser(false);
    setIsEditor(false);
    secureRemoveItem('user');
    secureRemoveItem('profile');
  };

  const checkLocalUser = () => {
    const storedUser = JSON.parse(secureGetItem('user'));
    if (storedUser) {
      const profileData = JSON.parse(secureGetItem('profile'));
      if (profileData) {
        try {
          const parsedUser = {
            access_token: storedUser.access_token
          };
          setUser(parsedUser);
          const decryptedProfile = {
            id: profileData.id,
            email: profileData.email,
            verified_email: profileData.verified_email,
            name: profileData.name,
            given_name: profileData.given_name,
            family_name: profileData.family_name,
            picture: profileData.picture,
            locale: profileData.locale
          };
          setProfile(decryptedProfile);
          fetchUserRole(decryptedProfile.email);
        } catch (error) {
          console.error("Kullanıcı bilgileri çözümlemesi başarısız:", error);
          logOut();
        }
      }
    }
  };

  useEffect(() => {
    try {
      checkLocalUser();
    } catch (error) {
      console.error("Yerel kullanıcı kontrolü sırasında hata oluştu:", error);
      logOut();
    }
  }, []);

  return (
    <>
      <div className='header bg-white p-1 flex justify-between items-center'>
        <h2>
          <strong className='text-primary'>X</strong>
          <strong className='text-secondary'> {' '}HR</strong>
        </h2>
      </div>
      {profile ? (
        <div>
          <div className="Launchpad-container">
            <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>DAY</h3>
            <div onClick={() => handleLaunchpadClick('/dayread/')} className="Launchpad">
              <p className='icon'><QrcodeOutlined /></p>
              <p>FIT Day Read</p>
            </div>
            {isAdmin && (
              <>
                <div onClick={() => handleLaunchpadClick('/qrreport/')} className="Launchpad">
                  <p className='icon'><FolderOpenTwoTone /></p>
                  <p>FIT Day Report</p>
                </div>
              </>
            )}
            {isCeo && (
              <>
                <div onClick={() => handleLaunchpadClick('/dayanalytical/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Day Analytical</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/daygraphic/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Day Graphic</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/qrreport/')} className="Launchpad">
                  <p className='icon'><FolderOpenTwoTone /></p>
                  <p>FIT Day Report</p>
                </div>
              </>
            )}
          </div>
          {(isUser || isCeo || isAdmin) && (
            <>
              <div className="Launchpad-container">
                <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>ROOM</h3>
                <div onClick={() => handleLaunchpadClick('/room/')} className="Launchpad">
                  <p className='icon'><CalendarTwoTone /></p>
                  <p>FIT Room</p>
                </div>
              </div>

              {/* <div className="Launchpad-container">
                <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>TASKS</h3>

              </div> */}

              <div className="Launchpad-container">
                <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>WORKING</h3>
                <div onClick={() => handleLaunchpadClick('/workingday/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Working Day</p>
                </div>

                <div onClick={() => handleLaunchpadClick('/parkinglot/')} className="Launchpad">
                  <p className='icon'><CarTwoTone /></p>
                  <p>FIT Parking Lot</p>
                </div>
              </div>
            </>
          )}

          {/* {isCeo && (
            <>
              <div className="Launchpad-container">
                <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>HR</h3>

              </div>
            </>
          )} */}
          {isEditor && (
            <>
              <div className="Launchpad-container">
                <h3 style={{ width: "100%", padding: "5px" }} className='text-primary'>DEV</h3>
                <div onClick={() => handleLaunchpadClick('/leaverequest/')} className="Launchpad">
                  <p className='icon'><IdcardTwoTone /></p>
                  <p>FIT Leave</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/leaveapproval/')} className="Launchpad">
                  <p className='icon'><IdcardTwoTone /></p>
                  <p>FIT Leave Approval</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/expense/')} className="Launchpad">
                  <p className='icon'><IdcardTwoTone /></p>
                  <p>FIT Expense</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/expenseapproval/')} className="Launchpad">
                  <p className='icon'><IdcardTwoTone /></p>
                  <p>FIT Expense Approval</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/dayerror/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Error QR</p>
                </div>

                <div onClick={() => handleLaunchpadClick('/candidates/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Candidates</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/positions/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Positions</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/fitprofile/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Profile</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/employeeinfo/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Employee Info</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/usages/')} className="Launchpad">
                  <p className='icon'><FundTwoTone /></p>
                  <p>FIT Usage</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/performance/')} className="Launchpad">
                  <p className='icon'><SnippetsTwoTone /></p>
                  <p>FIT Performance</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/assignperformance/')} className="Launchpad">
                  <p className='icon'><DiffTwoTone /></p>
                  <p>FIT Assign Performance</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/performancereport/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Performance Report</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/liableitems/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Liable Items</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/personnel/')} className="Launchpad">
                  <p className='icon'><IdcardTwoTone /></p>
                  <p>FIT Personnel</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/task/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Tasks</p>
                </div>
                <div onClick={() => handleLaunchpadClick('/organization/')} className="Launchpad">
                  <p className='icon'><PushpinTwoTone /></p>
                  <p>FIT Organization</p>
                </div>
              </div>
            </>
          )}

          <Button onClick={logOut} icon={<PoweroffOutlined />} className='logOut-Button'></Button>
        </div>
      ) : (
        <>
          <div className='button-container'>
            <Button onClick={login} className='authentication-button'><GoogleOutlined />Google ile Giriş Yap </Button >
          </div>
        </>
      )}
    </>
  );
};

export default Launchpad;
